export const defaultFiltersObject = () => ({
  shops: [],
  channel: [],
  status: [],
  reservedAt: null,
  pickUpAt: null,
  dropOffAt: null,
  updatedAt: null,
  searchValue: ""
})
